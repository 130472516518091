import _ from "lodash";

function getRefById(collection, field, value) {
  const item = _.find(collection, (p) => p[field] == value);
  return item.ref ? item.ref : undefined;
}

function loadImageByName(name) {
  try {
    return require("" + name);
  } catch (err) {
    console.error("could not load image for " + name);
    return null;
  }
}

export { getRefById, loadImageByName };
