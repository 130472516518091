import React, { Fragment } from "react";
import _ from "lodash";
import { useState, useEffect } from "react";
import { getRefById } from "./utils";

function AddPlayerToTeamDropDown(props) {
  function addPlayer(player_id) {
    props.db
      .collection("teams")
      .doc(props.team.id)
      .set(
        {
          players: [
            ...props.team.players.map((player) =>
              getRefById(props.players, "id", player)
            ),
            getRefById(props.players, "id", player_id),
          ],
        },
        { merge: true }
      );
  }

  return (
    <select onChange={(e) => addPlayer(e.target.value)}>
      <option></option>
      {props.items.map((item) => (
        <option key={[props.team.id, item.id].join("_")} value={item.id}>
          {item.name}
        </option>
      ))}
    </select>
  );
}

function SetupTeams(props) {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [currentTeamName, setCurrentHoleNumber] = useState("");
  const [currentTeamIcon, setCurrentHolePar] = useState("");

  useEffect(() => {
    const unsubscribePlayers = props.db
      .collection("players")
      .onSnapshot(function (querySnapshot) {
        setPlayers(
          querySnapshot.docs.map((player) => {
            return { id: player.id, ref: player.ref, ...player.data() };
          })
        );
      });
    const unsubscribeTeams = props.db
      .collection("teams")
      .onSnapshot(function (querySnapshot) {
        setTeams(
          querySnapshot.docs.map((team) => {
            const data = team.data();
            return {
              id: team.id,
              name: data.name,
              icon: data.icon,
              players: data.players.map((player) => player.id),
            };
          })
        );
      });

    return () => {
      unsubscribePlayers();
      unsubscribeTeams();
    };
  }, []);

  async function deleteAll() {
    const res = await props.db.collection("teams").get();
    res.forEach((e) =>
      e.ref.set(
        {
          players: [],
        },
        { merge: true }
      )
    );
  }

  async function createPools() {
    const res = await props.db.collection("pools").get();
    res.forEach((e) => e.ref.delete());
    props.db.collection("pools").add({
      number: 1,
      players: teams.map((t) => getRefById(players, "id", t.players[0])),
    });
    props.db.collection("pools").add({
      number: 2,
      players: teams.map((t) => getRefById(players, "id", t.players[1])),
    });
    props.db.collection("pools").add({
      number: 3,
      players: teams.map((t) => getRefById(players, "id", t.players[2])),
    });
  }

  const playerIdsInTeam = teams.map((t) => t.players).flat();
  const unassignedPlayers = _.filter(
    players,
    (p) => playerIdsInTeam.indexOf(p.id) < 0
  );

  let teamsTable = null;
  if (teams.length > 0) {
    teamsTable = (
      <Fragment>
        <h3>Teams</h3>
        <table className="setup-holes">
          <thead>
            <tr>
              <th>Name</th>
              <th>Icon</th>
              <th>Players</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {teams.map((t) => (
              <tr key={t.id}>
                <td>{t.name}</td>
                <td>{t.icon}</td>
                {t.players.length > 0 ? (
                  t.players.map((p) => (
                    <td>{_.find(players, (player) => player.id == p).name}</td>
                  ))
                ) : (
                  <td></td>
                )}
                <td>
                  <AddPlayerToTeamDropDown
                    team={t}
                    items={unassignedPlayers}
                    players={players}
                    db={props.db}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={deleteAll}>Clear All Teams</button>
        <button onClick={createPools}>Create Pools</button>
      </Fragment>
    );
  }
  return teamsTable;
}

export default SetupTeams;
