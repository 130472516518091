import React, { Fragment } from "react";
import _ from "lodash";
import { useState, useEffect } from "react";

function SetupHoles(props) {
  const [holes, setHoles] = useState([]);
  const [currentHoleNumber, setCurrentHoleNumber] = useState(1);
  const [currentHolePar, setCurrentHolePar] = useState(3);
  const [currentHoleLength, setCurrentHoleLength] = useState(0);
  useEffect(() => {
    // const unsubscribePools = props.db
    //   .collection("pools")
    //   .onSnapshot(function (querySnapshot) {
    //     setPools(
    //       querySnapshot.docs.map((pool) => {
    //         return {
    //           id: pool.id,
    //           number: 1,
    //           players: pool.data().players.map((player) => player.id),
    //         };
    //       })
    //     );
    //   });
    // const unsubscribePlayers = props.db
    //   .collection("players")
    //   .onSnapshot(function (querySnapshot) {
    //     setPlayers(
    //       querySnapshot.docs.map((player) => {
    //         return { id: player.id, ...player.data() };
    //       })
    //     );
    //   });
    // const unsubscribeTeams = props.db
    //   .collection("teams")
    //   .onSnapshot(function (querySnapshot) {
    //     setTeams(
    //       querySnapshot.docs.map((team) => {
    //         const data = team.data();
    //         return {
    //           id: team.id,
    //           name: data.name,
    //           players: data.players.map((player) => player.id),
    //         };
    //       })
    //     );
    //   });
    const unsubscribeHoles = props.db
      .collection("holes")
      .orderBy("number", "desc")
      .onSnapshot(function (querySnapshot) {
        setHoles(
          querySnapshot.docs.map((hole) => {
            const data = hole.data();
            return {
              id: hole.id,
              number: data.number,
              length: data.length,
              par: data.par,
              ref: hole.ref,
              scores: data.scores
                ? data.scores.map(({ completed, pool, winner }) => {
                    return {
                      completed,
                      pool,
                      winner,
                    };
                  })
                : [],
            };
          })
        );
      });
    return () => {
      unsubscribeHoles();
      //   unsubscribePlayers();
      //   unsubscribePools();
      //   unsubscribeTeams();
    };
  }, []);

  useEffect(() => {
    const highNumber = Math.max(...holes.map((hole) => hole.number));
    setCurrentHoleNumber(highNumber > 0 ? highNumber + 1 : 1);
  }, [holes]);

  async function deleteAll(e) {
    e.preventDefault();
    const res = await props.db.collection("holes").get();
    res.forEach((e) => e.ref.delete());
  }

  async function clearScores() {
    const res = await props.db.collection("holes").get();
    res.forEach((e) =>
      e.ref.set(
        {
          scores: [],
        },
        { merge: true }
      )
    );
  }

  function deleteHole(ref) {
    ref.delete();
  }

  let holesTable = null;

  if (holes.length > 0) {
    holesTable = (
      <Fragment>
        <h3>Holes</h3>
        <table className="setup-holes">
          <thead>
            <tr>
              <th>#</th>
              <th>Par</th>
              <th>Length</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {holes.map((h) => (
              <tr key={h.id}>
                <td>{h.number}</td>
                <td>{h.par}</td>
                <td>{h.length}</td>
                <td>
                  <button onClick={() => deleteHole(h.ref)}>X</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.db.collection("holes").add({
            number: currentHoleNumber,
            par: currentHolePar,
            length: currentHoleLength,
            scores: [],
          });
        }}
      >
        <label htmlFor="number">
          Number:
          <input
            type="number"
            name="number"
            value={currentHoleNumber}
            onChange={(e) => setCurrentHoleNumber(parseInt(e.target.value))}
          />
        </label>
        <label htmlFor="par">
          Par:
          <input
            type="number"
            name="par"
            value={currentHolePar}
            onChange={(e) => setCurrentHolePar(parseInt(e.target.value))}
          />
        </label>
        <label htmlFor="length">
          Length:
          <input
            type="number"
            name="length"
            value={currentHoleLength}
            onChange={(e) => setCurrentHoleLength(parseInt(e.target.value))}
          />
        </label>
        <button type="submit">Save</button>
        <button type="button" onClick={deleteAll}>
          Delete All
        </button>
        <button type="button" onClick={clearScores}>
          Clear Scores
        </button>
      </form>
      {holesTable}
    </Fragment>
  );
}

export default SetupHoles;
