import React, { Fragment } from "react";
import _ from "lodash";
import { useState, useEffect } from "react";
// import player_1_image from "./img/player.png";
// import player_2_image from "./img/player_2.png";
// import player_3_image from "./img/player_3.png";
import lohja_icon from "./img/lohja.svg";
import tre_kisko_icon from "./img/tre-kisko.svg";
import hesa_espoo_icon from "./img/hesa-espoo.svg";
import { useParams, Link } from "react-router-dom";
import { loadImageByName } from "./utils";

const team_icons = {
  Lohja: lohja_icon,
  "Hesa-Espoo": hesa_espoo_icon,
  "Tre-Kisko": tre_kisko_icon,
};

function Score(props) {
  let { poolNumber } = useParams();
  const [currentHole, setCurrentHole] = useState(1);
  const [holes, setHoles] = useState([]);
  const [pools, setPools] = useState([]);
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const myPool =
    pools.length > 0 ? _.find(pools, { number: parseInt(poolNumber) }) : null;
  useEffect(() => {
    const unsubscribePools = props.db
      .collection("pools")
      .orderBy("number")
      .onSnapshot(function (querySnapshot) {
        setPools(
          querySnapshot.docs.map((pool) => {
            return {
              id: pool.id,
              number: pool.data().number,
              players: pool.data().players.map((player) => player.id),
            };
          })
        );
      });
    const unsubscribePlayers = props.db
      .collection("players")
      .onSnapshot(function (querySnapshot) {
        setPlayers(
          querySnapshot.docs.map((player) => {
            const data = player.data();
            return {
              id: player.id,
              image: `./img/${data.name
                .replace(/[^a-z0-9]/gi, "_")
                .toLowerCase()}.png`,
              ...data,
            };
          })
        );
      });
    const unsubscribeTeams = props.db
      .collection("teams")
      .onSnapshot(function (querySnapshot) {
        setTeams(
          querySnapshot.docs.map((team) => {
            const data = team.data();
            return {
              id: team.id,
              name: data.name,
              players: data.players.map((player) => player.id),
            };
          })
        );
      });
    const unsubscribeHoles = props.db
      .collection("holes")
      .orderBy("number")
      .onSnapshot(function (querySnapshot) {
        setHoles(
          querySnapshot.docs.map((hole) => {
            const data = hole.data();
            return {
              id: hole.id,
              number: data.number,
              length: data.length,
              par: data.par,
              scores: data.scores
                ? data.scores.map(({ completed, pool, winner }) => {
                    return {
                      completed,
                      pool,
                      winner,
                    };
                  })
                : [],
            };
          })
        );
      });
    return () => {
      unsubscribeHoles();
      unsubscribePlayers();
      unsubscribePools();
      unsubscribeTeams();
    };
  }, []);

  if (props.choosePool && pools.length > 0) {
    return (
      <Fragment>
        <h3>Choose Pool to Score</h3>
        {pools.map((p) => (
          <div className="btn">
            <Link to={`/score/${p.number}`}>
              Score Pool {p.number} (
              {p.players.map((p) => getPlayerFieldbyId(p, "name")).join(", ")})
            </Link>
          </div>
        ))}
      </Fragment>
    );
  }

  if (players.length == 0 || holes.length == 0 || pools.length == 0)
    return <p>Loading...</p>;

  function prevHole() {
    let nextHole = currentHole - 1;
    if (nextHole == 0) nextHole = holes.length;
    setCurrentHole(nextHole);
  }

  function nextHole() {
    let nextHole = currentHole + 1;
    if (nextHole == holes.length + 1) nextHole = 1;
    setCurrentHole(nextHole);
  }

  function setWinner(hole_number, pool_id, winner) {
    const hole = _.find(holes, (h) => h.number == hole_number);
    const scores = hole.scores;
    let score = _.find(scores, (s) => s.pool == pool_id);
    if (score) {
      score.winner = winner;
      score.completed = true;
    } else {
      scores.push({
        pool: pool_id,
        winner,
        completed: true,
      });
    }
    props.db.collection("holes").doc(hole.id).set(
      {
        scores: scores,
      },
      { merge: true }
    );
  }

  function getPlayerScore(id) {
    return holes.map((h) => h.scores.filter((s) => s.winner == id)).flat()
      .length;
  }

  function getTeamScore(id) {
    const teamPlayers = _.find(teams, (t) => t.id == id).players;
    return holes
      .map((h) => h.scores.filter((s) => teamPlayers.includes(s.winner)))
      .flat().length;
  }

  function getHoleWinnerByPool(hole_number, pool) {
    const score = _.find(
      _.find(holes, (h) => h.number == hole_number).scores,
      (s) => s.pool == pool
    );
    return score ? score.winner : null;
  }

  function getScoreCount(poolNum) {
    const poolByNumber = _.find(pools, (p) => p.number == poolNum);
    return holes
      .map((h) => h.scores)
      .flat()
      .filter((s) => s.pool == poolByNumber.id).length;
  }

  const totals = teams.map((t) => (
    <div key={t.id} className="score-team">
      <div className="name-team">{t.name}</div>
      <div className="icon-team">
        <img alt="tre-kisko" src={team_icons[t.name]} />
      </div>
      <div className="score-team">{getTeamScore(t.id)}</div>
    </div>
  ));

  const holeWinnerId = getHoleWinnerByPool(currentHole, myPool.id);
  const poolPlayers = _.find(pools, { number: parseInt(poolNumber) })
    .players.map((p) => _.find(players, (player) => player.id == p))
    .map((p) => (
      <div key={p.id} className="player">
        <div className="name">{p.name}</div>
        <div
          className={`image ${holeWinnerId === p.id ? "selected" : ""}`}
          onClick={() => {
            setWinner(currentHole, myPool.id, p.id);
          }}
        >
          <img alt="player 1" src={loadImageByName(p.image)} />
        </div>
        <div className="score">{getPlayerScore(p.id)}</div>
      </div>
    ));

  function poolStats(poolNumber) {
    const poolPlayerz = _.find(pools, { number: parseInt(poolNumber) })
      .players.map((p) => _.find(players, (player) => player.id == p))
      .map((p) => (
        <div key={p.id} className="player">
          <div className="name">{p.name}</div>
          <div className="image">
            <img alt="player 1" src={loadImageByName(p.image)} />
          </div>
          <div className="score">{getPlayerScore(p.id)}</div>
        </div>
      ));

    const throughHoles = getScoreCount(poolNumber);
    return (
      <div key={`poolstats_${poolNumber}`} className="section">
        <h3>
          Pool {poolNumber}&nbsp;&nbsp;&nbsp;
          <span style={{ color: "#777" }}>
            {!!throughHoles && `${throughHoles} holes`}
          </span>
        </h3>
        <div className="pool-container">
          <div className="pool">{poolPlayerz}</div>
        </div>
      </div>
    );
  }

  const otherPools = pools
    .filter((pool) => pool.number != myPool.number)
    .map((pool) => pool.number);

  function currentHoleByNumber(number) {
    return _.find(holes, (h) => h.number == number);
  }

  function getPlayerFieldbyId(id, field) {
    if (id == 0) return "=";
    const player = _.find(players, (player) => player.id == id);
    return player ? player[field] : "-";
  }

  return (
    <div className="container">
      <div className="hole">
        <p>
          Hole #{currentHole} - Par {currentHoleByNumber(currentHole).par}{" "}
        </p>
      </div>
      <main>
        <div className="pools">
          <div className="pool-container section">
            <div className="pool">
              {poolPlayers}
              <div className="player">
                <div
                  className={`tie ${holeWinnerId == 0 ? "selected" : ""}`}
                  onClick={() => {
                    setWinner(currentHole, myPool.id, 0);
                  }}
                >
                  <svg
                    width="278px"
                    height="482px"
                    viewBox="0 0 278 482"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <title>tie-svgrepo-com</title>
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g id="tie-svgrepo-com">
                        <path
                          d="M202.545,113.201 L173.557,65.541 C172.202,63.281 169.717,61.852 167.157,61.852 L110.611,61.852 C107.976,61.852 105.566,63.282 104.211,65.541 L75.147,113.201 C73.716,115.611 73.716,118.624 75.147,121.032 L97.133,157.099 C98.488,159.358 100.898,160.713 103.533,160.713 L174.234,160.713 C176.794,160.713 179.279,159.358 180.634,157.099 L202.545,121.032 C204.051,118.624 204.051,115.611 202.545,113.201 Z"
                          id="Path"
                        />
                        <path
                          d="M223.853,408.205 L184.939,172.999 C181.691,174.755 178.026,175.771 174.233,175.771 L103.531,175.771 C99.663,175.771 95.919,174.738 92.622,172.911 L53.763,408.205 C53.311,410.839 54.29,413.55 56.323,415.282 L133.951,480.11 C135.382,481.316 137.113,481.919 138.77,481.919 C140.502,481.919 142.234,481.316 143.664,480.11 L221.292,415.282 C223.326,413.55 224.305,410.839 223.853,408.205 Z"
                          id="Path"
                        />
                        <path
                          d="M276.333,51.46 L246.667,3.572 C245.462,1.69 243.58,0.486 241.472,0.109 C239.288,-0.266 237.105,0.335 235.373,1.767 L178.292,49.796 C181.605,51.699 184.454,54.432 186.476,57.807 L215.414,105.38 C216.22,106.669 216.83,108.035 217.346,109.428 L274.752,61.173 C277.613,58.763 278.291,54.624 276.333,51.46 Z"
                          id="Path"
                        />
                        <path
                          d="M42.469,1.767 C40.813,0.335 38.629,-0.266 36.446,0.109 C34.262,0.485 32.38,1.689 31.251,3.572 L1.585,51.46 C-0.373,54.623 0.305,58.763 3.166,61.173 L60.533,109.445 C61.016,108.109 61.461,106.755 62.205,105.506 L91.356,57.705 C93.354,54.361 96.206,51.632 99.541,49.74 L42.469,1.767 Z"
                          id="Path"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h3>Total Scores</h3>
            <div className="total-score">{totals}</div>
          </div>
          {otherPools.map((pool) => {
            return poolStats(pool);
          })}
          <div className="section">
            <h3>Total Scores</h3>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  {pools.map((p) => (
                    <th>Pool {p.number}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {holes.map((h) => (
                  <tr>
                    <td>{h.number}.</td>
                    {pools.map((p) => (
                      <td>
                        {getPlayerFieldbyId(
                          getHoleWinnerByPool(h.number, p.id),
                          "image"
                        ) &&
                        getPlayerFieldbyId(
                          getHoleWinnerByPool(h.number, p.id),
                          "image"
                        ) != "=" &&
                        getPlayerFieldbyId(
                          getHoleWinnerByPool(h.number, p.id),
                          "image"
                        ) != "-" ? (
                          <img
                            className="table-image"
                            src={loadImageByName(
                              getPlayerFieldbyId(
                                getHoleWinnerByPool(h.number, p.id),
                                "image"
                              )
                            )}
                          />
                        ) : (
                          getPlayerFieldbyId(
                            getHoleWinnerByPool(h.number, p.id),
                            "image"
                          )
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
      <footer className="footer">
        <div className="controls">
          <button className="prev" onClick={prevHole}>
            <span role="img" aria-label="previous">
              👈
            </span>
          </button>
          <button>
            <span role="img" aria-label="finish">
              <Link to="/">🏁</Link>
            </span>
          </button>
          <button className="next" onClick={nextHole}>
            <span role="img" aria-label="next">
              👉
            </span>
          </button>
        </div>
      </footer>
    </div>
  );
}

export default Score;
