import React, { Fragment } from "react";
import _ from "lodash";
import { useState, useEffect } from "react";

function SetupPlayers(props) {
  const [players, setPlayers] = useState([]);
  useEffect(() => {
    const unsubscribePlayers = props.db
      .collection("players")
      .orderBy("name")
      .onSnapshot(function (querySnapshot) {
        setPlayers(
          querySnapshot.docs.map((player) => {
            return { id: player.id, ...player.data() };
          })
        );
      });
    return () => {
      unsubscribePlayers();
    };
  }, []);

  if (players.length > 0) {
    return (
      <Fragment>
        <h3>Players</h3>
        <ul className="players-list">
          {players.map((p) => (
            <li>{p.name}</li>
          ))}
        </ul>
      </Fragment>
    );
  }
  return null;
}

export default SetupPlayers;
