import React from "react";
import { initializeApp, firestore } from "firebase";
import Score from "./Score";
import SetupHoles from "./SetupHoles";
import SetupPlayers from "./SetupPlayers";
import SetupTeams from "./SetupTeams";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyB94_taG8eUDCa7Pwb7iQjADLiC82nQRCI",
  authDomain: "tamina-matchplay.firebaseapp.com",
  databaseURL: "https://tamina-matchplay.firebaseio.com",
  projectId: "tamina-matchplay",
  storageBucket: "tamina-matchplay.appspot.com",
  messagingSenderId: "963895595915",
  appId: "1:963895595915:web:5024b9fcd74463e603958e",
};

initializeApp(firebaseConfig);
const db = firestore();
window.db = db;

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Score db={db} choosePool={true} />
        </Route>
        <Route path="/score/:poolNumber">
          <Score db={db} />
        </Route>
        <Route exact path="/setup">
          <div className="btn">
            <Link to="/setup/players">Setup Players</Link>
          </div>
          <div className="btn">
            <Link to="/setup/holes">Setup Holes</Link>
          </div>
          <div className="btn">
            <Link to="/setup/teams">Setup Teams &amp; Pools</Link>
          </div>
        </Route>
        <Route path="/setup/holes">
          <SetupHoles db={db} />
        </Route>
        <Route path="/setup/players">
          <SetupPlayers db={db} />
        </Route>
        <Route path="/setup/teams">
          <SetupTeams db={db} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
